import React, { useEffect, useRef, useState } from 'react';
import Image from '@src/elements/Image';
import styles from './GalleryModal.module.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ButtonIcon } from '../Button';
import { COLOR_LIME } from '@src/types/colors';
import classNames from 'classnames';
import { CloudinarySource } from '@src/types/cloudinary';
import { useGetOS } from '@src/hooks/useGetOS';

interface GalleryModal {
  gallery: CloudinarySource[];
  id?: string;
  initial?: number;
  onRequestClose?: () => void;
  floorPlansIndex?: number;
}

const Thumbnails: React.FC<{
  gallery: CloudinarySource[];
  selected: number;
  setSelected: (value: number) => void;
}> = ({ gallery, selected, setSelected }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [overflowing, setOverflowing] = useState(false);

  useEffect(() => {
    // workaround to delay scrollIntoView as it conflicts with the Carousel's Touch Events
    setTimeout(() => {
      const thumbRef = containerRef?.current?.children?.[selected];
      thumbRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }, 100);

    const containerWidth = containerRef?.current?.clientWidth || 0;
    const scrollWidth = containerRef?.current?.scrollWidth || 0;

    if (containerWidth < scrollWidth) {
      setOverflowing(true);
    }
  }, [selected]);

  return (
    <div
      ref={containerRef}
      className={classNames(styles.thumbnails, {
        [styles.overflowing]: overflowing,
        [styles.windowsScrollbar]: useGetOS() === 'Windows'
      })}
    >
      {gallery.map((image: CloudinarySource, index: number) => {
        return (
          <div
            key={index}
            className={classNames(styles.thumb, {
              [styles.selected]: index === selected
            })}
            onClick={() => {
              setSelected(index);
            }}
          >
            <Image
              image={image}
              caption={image?.context?.custom?.caption || ''}
              thumb
              data-testid="selectedImage"
            />
          </div>
        );
      })}
    </div>
  );
};

const GalleryModal: React.FC<GalleryModal> = ({
  gallery,
  initial = 0,
  onRequestClose,
  floorPlansIndex
}) => {
  const [selected, setSelected] = useState<number>(initial);

  const previousImage = () => {
    if (selected === 0) {
      setSelected(gallery.length - 1);
    } else {
      setSelected(selected - 1);
    }
  };

  const nextImage = () => {
    if (selected === gallery.length - 1) {
      setSelected(0);
    } else {
      setSelected(selected + 1);
    }
  };

  return (
    <div className={styles.galleryModal}>
      <div className={styles.carouselContainer}>
        <div className={styles.carouselWrapper}>
          <div className={styles.carousel}>
            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={48}
              infiniteLoop={true}
              showThumbs={false}
              autoPlay={false}
              selectedItem={selected}
              onChange={setSelected}
            >
              {gallery.map((image: CloudinarySource, index: number) =>
                index < (floorPlansIndex || gallery.length) ? (
                  <Image
                    key={index}
                    image={image}
                    caption={image.context?.custom?.caption || ''}
                    aspectRatio={'16:9'}
                    priority={index === 0}
                  />
                ) : (
                  <Image
                    key={index}
                    image={image}
                    caption={image.context?.custom?.caption || ''}
                    contain
                    priority={index === 0}
                  />
                )
              )}
            </Carousel>
          </div>
          <div className={styles.buttonLeft}>
            <ButtonIcon
              icon="ChevronLeft"
              color={COLOR_LIME}
              fillVariant="filled"
              onClick={previousImage}
              aria-label="Previous Image"
              data-testid="previousImageBtn"
            />
          </div>
          <div className={styles.buttonRight}>
            <ButtonIcon
              icon="ChevronRight"
              color={COLOR_LIME}
              fillVariant="filled"
              onClick={nextImage}
              aria-label="Next Image"
              data-testid="nextImageBtn"
            />
          </div>
        </div>
        <div className={styles.caption}>
          {gallery?.[selected]?.context?.custom?.caption}
        </div>
        <div className={styles.mobileControls}>
          <ButtonIcon
            icon="ChevronLeft"
            color={COLOR_LIME}
            fillVariant="filled"
            onClick={previousImage}
            aria-label="Previous Image"
            data-testid="previousImageBtn"
          />
          <ButtonIcon
            icon="ChevronRight"
            color={COLOR_LIME}
            fillVariant="filled"
            onClick={nextImage}
            aria-label="Next Image"
            data-testid="nextImageBtn"
          />
        </div>
      </div>
      <div className={styles.thumbsWrapper}>
        <Thumbnails
          gallery={gallery}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className={styles.closeButton}>
        <ButtonIcon
          icon="Close"
          color={COLOR_LIME}
          fillVariant="filled"
          onClick={onRequestClose}
          aria-label="Close"
          data-testid="closeBtn"
        />
      </div>
    </div>
  );
};

export default GalleryModal;
